<template>
  <component
    :is="isLinkValid(link) ? 't3-link' : 'div'"
    :to="link"
    class="featured-case-study-slider__item"
    :class="variantColorClass"
  >
    <div class="featured-case-study-slider__item-preview">
      <UiImg
        :image="image"
        :sizes="{
          xs: '400',
          sm: '640'
        }"
      />
    </div>

    <div v-if="label || name" class="featured-case-study-slider__item-content">
      <span v-if="label">
        {{ label }}
      </span>

      <T3HtmlParser tag="h3" :content="name" />
    </div>
  </component>
</template>

<script setup lang="ts">
import type { UiCeProductBoxesItemProps } from '~/types'
import { UiImg } from '~ui/components'
import useTheming from '~ui/composables/useTheming'
import { isLinkValid } from '~ui/helpers/linkUtils'

const props = defineProps<Partial<UiCeProductBoxesItemProps>>()

const variantColorClass = useTheming(props.variant, 'variant')
</script>

<style lang="scss">
.featured-case-study-slider__item {
  display: grid;
  gap: rem(32px);
  padding-bottom: rem(24px);
  position: relative;
  direction: ltr;

  @include media-query(sm) {
    width: rem(460px);
  }

  @include media-query(md) {
    width: rem(550px);
  }

  @include media-query(lg) {
    width: rem(640px);
  }

  @include media-query(max sm) {
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 100%;
        box-shadow: 0 1px 0 rgba(color(white), 16%);
      }

      margin-bottom: rem(56px);
    }
  }

  &-preview {
    overflow: hidden;
    border-radius: 2px;
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;

    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &-content {
    & > span {
      width: fit-content;
      font-size: rem(18px);
      border-radius: 2px;
      color: var(--variant__theme-color, color(white));
      background-color: var(--variant__gradient-100);
      border: 1px solid var(--variant__gradient-300);
      padding: em(3px) em(12px);
    }

    & > h3 {
      font-size: rem(32px);
      font-weight: 600;
      margin: 0;
      color: color(white);
    }

    & span + h3 {
      margin-top: rem(16px);
    }
  }
}
</style>
