<template>
  <div class="featured-case-study-slider">
    <div class="featured-case-study-slider__content">
      <div
        v-if="
          header || (subheader && headerLink) || bodytext || itemList.length
        "
        class="featured-case-study-slider__header"
      >
        <div
          v-if="header || bodytext"
          class="featured-case-study-slider__header-wrapper"
        >
          <UiHeader
            v-if="header"
            :header="header"
            :header-layout="headerLayout"
            :header-size="headerSize"
            class="featured-case-study-slider__heading"
          />

          <T3HtmlParser
            v-if="bodytext"
            :content="bodytext"
            class="featured-case-study-slider__text"
          />
        </div>

        <div
          v-if="(subheader && headerLink) || itemList.length"
          class="featured-case-study-slider__controls"
        >
          <ButtonBase
            v-if="subheader && headerLink"
            class="featured-case-study-slider__cta"
            :button="{ content: subheader, link: headerLink }"
          />

          <button
            ref="prev"
            aria-label="prev"
            class="button-base button-base--solid-macopedia-blue button-base--large swiper-button swiper-button-prev"
          />
          <button
            ref="next"
            aria-label="next"
            class="button-base button-base--solid-macopedia-blue button-base--large swiper-button swiper-button-next"
          />
        </div>
      </div>

      <div v-if="itemList.length" class="featured-case-study-slider__wrapper">
        <div class="featured-case-study-slider__list">
          <template v-for="client in itemList" :key="client.id">
            <FeaturedCaseStudySliderItem v-bind="client" />
          </template>
        </div>

        <UiSwiper
          :options="swiperOptions"
          class="featured-case-study-slider__carousel"
        >
          <SwiperSlide v-for="client in itemList" :key="client.id">
            <FeaturedCaseStudySliderItem v-bind="client" />
          </SwiperSlide>
        </UiSwiper>
      </div>
    </div>

    <div ref="pagination" class="swiper-slider-progress" />
  </div>
</template>

<script setup lang="ts">
import { Navigation, Pagination } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'
import { SwiperSlide } from 'swiper/vue'
import { computed, ref } from 'vue'

import type { UiCeProductBoxesProps } from '~/types'
import { ButtonBase, UiHeader, UiSwiper } from '~ui/components'

import FeaturedCaseStudySliderItem from './FeaturedCaseStudySliderItem.vue'

defineProps<UiCeProductBoxesProps>()

const pagination = ref(null)
const next = ref(null)
const prev = ref(null)

const swiperOptions = computed(
  (): SwiperOptions => ({
    modules: [Navigation, Pagination],
    slidesPerView: 'auto',
    pagination: {
      el: pagination.value,
      type: 'progressbar'
    },
    spaceBetween: 24,
    navigation: {
      nextEl: next.value,
      prevEl: prev.value
    }
  })
)
</script>

<style lang="scss">
.featured-case-study-slider {
  padding-block: rem(32px);
  overflow: hidden;

  @include media-query(sm) {
    padding-block: rem(96px);
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;
    row-gap: rem(40px);

    @include media-query(sm) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-column: span 1;
    z-index: z-index(over);

    @include media-query(sm) {
      min-height: rem(400px);
      padding: rem(24px);
      border: 1px solid rgba(color(white), 16%);
      border-radius: em(4px);
    }

    @include media-query(md) {
      min-height: rem(450px);
    }

    @include media-query(lg) {
      min-height: rem(500px);
    }

    @include media-query(max sm) {
      &-wrapper {
        display: none;
      }
    }
  }

  &__heading.ui-header > * {
    font-weight: 600;
    text-align: left;

    @include media-query(max lg) {
      font-size: clamp(rem(32px), 3.5vw, rem(44px));
    }
  }

  &__text > * {
    font-size: rem(20px);
  }

  &__wrapper {
    grid-column: span 2;
  }

  &__list {
    @include media-query(sm) {
      display: none;
    }
  }

  &__carousel.swiper {
    @include media-query(max sm) {
      display: none;
    }

    padding-left: rem(24px);

    & .swiper-slide {
      width: fit-content;
    }
  }

  &__controls {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: rem(24px);

    @include media-query(sm md) {
      gap: rem(16px);
    }

    & .button-base {
      height: rem(56px);
      width: 100%;
      margin: 0;

      @include media-query(max sm) {
        height: rem(64px);
      }

      & > span {
        height: 100%;
      }

      &.swiper-button {
        @include media-query(max sm) {
          display: none;
        }

        background-color: transparent;
        transition: background-color 0.2s;

        &::after {
          background-size: 50%;
          filter: invert(1);
          transition: opacity 0.2s;
        }

        &:is(:hover, :focus-visible) {
          background-color: rgba(color(white), 3%);
        }

        &:active {
          background-color: rgba(color(white), 6%);
        }

        &:disabled {
          cursor: not-allowed;
          pointer-events: none;

          &::after {
            opacity: 0.2;
          }
        }
      }
    }
  }

  &__cta {
    grid-column: span 2;

    & span {
      padding-inline: em(8px);
    }

    @include media-query(max sm) {
      margin-bottom: rem(8px);
      grid-column: 1 / -1;
    }
  }

  & .swiper-slider-progress {
    @include media-query(max sm) {
      display: none;
    }

    margin-top: rem(48px);
    position: relative;
    width: 100%;
    height: 1px;
    background-color: rgba(color(white), 16%);

    --swiper-pagination-color: #{color(primary)};

    & > .swiper-pagination-progressbar-fill {
      display: block;
      background-color: color(primary);
      height: 3px;
      translate: 0 -1px;
      transform-origin: left;
    }
  }
}
</style>
